import React, { useState, useEffect } from "react"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import PageTitleSection from "../components/PageTitleSection"
import { Link, graphql, useStaticQuery } from "gatsby"
//import AOS from "aos"
import "aos/dist/aos.css" // You can also use <link> for styles

import Layout from "../components/Layout"
import InsightsItem from "../components/InsightsItem"

const Insights = ({ data: { page, posts } }) => {
  let AOS

  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos")
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })

  return (
    <Layout page={page} showFooter={page.pageSettings.showFooter}>
      <Seo seo={page.seo} />
      <MountainHeader headerImages={page.headerSettings.headerImages} />

      <PageWrapper additionalClassName="insights">
        <PageTitleSection
          title={page.headerSettings.title ?? ""}
          subtitle={page.headerSettings.subTitle ?? ""}
        />
        <div className="container blog-items--container">
          {posts?.edges?.map((post, index) => (
            <>{post?.node?.title && <InsightsItem post={post} key={index} />}</>
          ))}
          {/* <div className="insigths-pagination">
            <div className="insigth-pagination--items">
              <div className="insigth-pagination-item active-pagination-item">
                1
              </div>
              <div className="insigth-pagination-item">2</div>
              <div className="insigth-pagination-item">3</div>
              <div className="insigth-pagination-item">...</div>
              <div className="insigth-pagination-item">10</div>
            </div>
          </div> */}
        </div>
      </PageWrapper>
    </Layout>
  )
}

export default Insights

export const InsightsQuery = graphql`
  query InsightsId(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      ...pageFields
    }
    posts: allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          content
          date
          uri
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          author {
            node {
              avatar {
                url
                foundAvatar
              }
              name
              username
              lastName
              firstName
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
        totalCount
      }
    }
  }
`
