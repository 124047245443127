import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import moment from "moment"
import RightArrow from "../assets/icons/right-arrow.png"
import Button from "../components/Button"

const InsightsItem = ({ post: { node } }) => {
  const truncateText = (text, limit = 200) => {
    if (text.length <= limit) {
      return text
    }
    return text.slice(0, limit) + "..."
  }

  const truncatedText = truncateText(node.content)

  return (
    <>
      <Link to={node?.uri} className="blog-item__container">
        <div className="blog-item__image">
          <GatsbyImage
            image={
              node?.featuredImage?.node?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            alt={node?.altText}
            style={{ marginBottom: 50, height: "300px" }}
          />
        </div>
        <div className="blog-item__content">
          <div className="blog-item__bio">
            <img
              className="blog-item__avatar"
              src={node?.author?.node?.avatar?.url}
            />
            <span className="blog-item__date">
              {moment(node?.date).format("MMMM Do YYYY").toUpperCase()}
            </span>
          </div>
          <div className="blog-item__title">{node?.title}</div>
          <div
            className="blog-item__description"
            dangerouslySetInnerHTML={{ __html: truncatedText }}
          ></div>
          <Button text="READ ARTICLE" width="14rem" />
        </div>
      </Link>
    </>
  )
}

export default InsightsItem
